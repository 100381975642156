import React, { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '~/services/api';

import { ListProps } from '../protocols';
import { ContentList, Option } from '../styles';

export const Lista: React.FC<ListProps> = ({
  inputRef,
  setIsOpen,
  setFilter,
  hasNextPage,
  fetchNextPage,
  data,
  selected,
  setSelected,
  isFetching,
  getProduct,
  queryName,
  queryClient,
  selectedTipoDeBusca,
  buscaNasLojas,
  buscaItensInativos,
  setValue,
  name,
  wrapperRef,
  listWidth = undefined,
}) => {
  const [itemsArray, setItemsArray] = useState<
    | {
        value: string;
        label: string;
      }[]
  >([]);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [mouseFocusedIndex, setMouseFocusedIndex] = useState(-1);

  const onResetStates = useCallback(() => {
    setItemsArray([]);
    setFocusedIndex(-1);
    setMouseFocusedIndex(-1);
  }, []);

  /**
   * useCloseOptions
   * Fecha lista de opções ao clicar fora
   */
  function useCloseOptions(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (event.target === inputRef.current) {
          return;
        }
        if (ref.current && !ref.current.contains(event.target)) {
          if (inputRef) inputRef.current.value = '';
          setFilter('');
          queryClient.removeQueries(queryName);
          setIsOpen(false);
          onResetStates();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useCloseOptions(wrapperRef);

  /**
   * onScroll
   * Verifica se o scroll chegou ao final da lista de opções
   */
  const onScroll = useCallback(async () => {
    if (wrapperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;
      const zoomLevel = Math.round(window.devicePixelRatio * 100);
      const offset = Math.abs(100 - zoomLevel); // essa é a margem que mencionei
      if (scrollTop + clientHeight >= scrollHeight - offset) {
        if (hasNextPage) {
          await fetchNextPage();
        }
      }
    }
  }, [fetchNextPage, hasNextPage, wrapperRef]);

  /**
   * handleClick
   * Seleciona item da lista
   * Efetua a busca na api pelos dados do item
   * Retorna dados do item selecionado
   */
  const handleClick = useCallback(
    async (option: {
      label: string;
      value: number;
      filter?: string;
      selecionarItensContendo?: boolean;
    }) => {
      try {
        const { data: dataResponse } = await api.get('/busca-produto/data', {
          params: {
            tipoDeBusca: selectedTipoDeBusca.value,
            itemData: option,
            lojas: buscaNasLojas,
            buscaItensInativos,
          },
        });
        if (dataResponse.success) {
          setSelected(option);
          if (setValue) setValue(name, option);
          getProduct(dataResponse.data);
          setIsOpen(false);
          onResetStates();
        }
      } catch (error: any) {
        if (error?.stack?.includes('Cannot read properties of undefined'))
          return toast.warning('Nenhum produto retornado.');

        if (error.data?.message) {
          toast.error(error.data.message);
        } else {
          if (error.stack) {
            if (
              error.stack.includes(
                "Cannot read properties of undefined (reading 'cod_produto')\n",
              )
            )
              return toast.warning('Nenhum produto encontrado');
          }
          toast.error(String(error));
        }
      }
    },
    [
      buscaItensInativos,
      buscaNasLojas,
      getProduct,
      selectedTipoDeBusca.value,
      setIsOpen,
      setSelected,
      onResetStates,
    ],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const wrapperElement = wrapperRef.current;
      if (!wrapperElement) return;

      const options = wrapperElement.getElementsByTagName(
        'li',
      ) as HTMLCollectionOf<HTMLLIElement>;
      if (!options.length) return;

      const pageSize = Math.floor(
        wrapperElement.clientHeight / options[0].clientHeight,
      );

      const handleNavigation = (newIdx: number) => {
        setFocusedIndex(Math.max(0, Math.min(newIdx, options.length - 1)));
      };

      const keyActions: Record<string, () => void> = {
        ArrowDown: () => handleNavigation(focusedIndex + 1),
        ArrowUp: () => handleNavigation(focusedIndex - 1),
        PageDown: () => handleNavigation(focusedIndex + pageSize),
        PageUp: () => handleNavigation(focusedIndex - pageSize),
        Home: () => handleNavigation(0),
        End: () => handleNavigation(options.length - 1),
        Enter: () => {
          const activeIndex =
            focusedIndex >= 0 && focusedIndex < options.length
              ? focusedIndex
              : mouseFocusedIndex;
          if (activeIndex >= 0 && activeIndex < options.length) {
            options[activeIndex].click();
            (document.activeElement as HTMLElement)?.blur();
          }
        },
      };

      if (keyActions[event.key]) {
        event.preventDefault();
        keyActions[event.key]();
      }
    },
    [focusedIndex, mouseFocusedIndex, wrapperRef],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    const wrapperElement = wrapperRef.current;
    if (!wrapperElement) return;

    const options = wrapperElement.getElementsByTagName('li');
    if (focusedIndex < 0 || focusedIndex >= options.length) return;

    const focusedOption = options[focusedIndex] as HTMLElement;
    const { clientHeight: wrapperHeight } = wrapperElement;
    const { offsetTop: optionOffsetTop, offsetHeight: optionHeight } =
      focusedOption;

    wrapperElement.scrollTop =
      optionOffsetTop - wrapperHeight / 2 + optionHeight / 2;
    focusedOption.focus();
  }, [focusedIndex, wrapperRef, data]);

  useEffect(() => {
    const preventScroll = (e: WheelEvent) => {
      if (wrapperRef.current && wrapperRef.current.contains(e.target as Node))
        setFocusedIndex(mouseFocusedIndex);
    };

    document.addEventListener('wheel', preventScroll, { passive: false });
    return () => document.removeEventListener('wheel', preventScroll);
  }, [mouseFocusedIndex, wrapperRef]);

  const removeDuplicates = <T,>(items: T[]): T[] => {
    const seen = new Set<T>();
    return items.filter((item) => {
      if (seen.has(item)) return false;
      seen.add(item);
      return true;
    });
  };

  const loadItems = useCallback(() => {
    if (!data) return;

    const { pages } = data;
    const newItems: any[] = pages
      .filter((page) => page.success)
      .flatMap((page) => page.data);

    const uniqueItems = removeDuplicates(newItems);
    setItemsArray(uniqueItems);
  }, [data]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const renderNotFound = useCallback(
    (message: string): JSX.Element => (
      <Option className="not-found">{message}</Option>
    ),
    [],
  );
  /**
   * renderOptions
   * Renderiza lista de opções
   */
  const renderOptions = useCallback(() => {
    /**
     * Caso a variavel data não seja undefined
     */
    if (data !== undefined) {
      /**
       * Caso existam dados de acordo com a busca efetuada
       * os mesmos serão renderizados na lista de opções.
       * Caso contrario entrarão na condicional abaixo exebindo
       * "Nenhum registro encontrado."
       */
      if (!data.pages[0].message)
        return renderNotFound('Digite ao menos 3 letras para pesquisar.');

      const pageMessage = data.pages[0].message;
      const noRecordsFoundMessage = 'Nenhum registro encontrado.';

      if (pageMessage === 'url not found' || data.pages.length <= 0)
        return renderNotFound(noRecordsFoundMessage);

      if (
        pageMessage === 'Registros encontrados' &&
        data.pages[0].data.length <= 0
      )
        return renderNotFound(noRecordsFoundMessage);

      return itemsArray.map((item: any, index: number) => {
        const itemClassNames = [
          selected.value === item.value ? 'selected' : '',
          focusedIndex === index ? 'focused' : '',
        ]
          .filter(Boolean)
          .join(' ');

        return (
          <Option
            key={item.value}
            className={itemClassNames}
            onMouseEnter={() => setMouseFocusedIndex(index)}
            onMouseLeave={() => setMouseFocusedIndex(-1)}
            onClick={() => {
              handleClick(item);
              if (inputRef) {
                inputRef.current.placeholder = item.label;
                inputRef.current.value = '';
              }
              queryClient.removeQueries(`input_select_${queryName}`);
              setFilter('');
            }}
            onKeyPress={() => {
              handleClick(item);
              if (inputRef) {
                inputRef.current.placeholder = item.label;
                inputRef.current.value = '';
              }
              queryClient.removeQueries(`input_select_${queryName}`);
              setFilter('');
            }}
          >
            {item.label.includes('|') ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {(() => {
                  const [produto, estoque] = item.label.split('|');
                  return (
                    <>
                      <span>{produto}</span>
                      <span>| {estoque} |</span>
                    </>
                  );
                })()}
              </div>
            ) : (
              item.label
            )}
          </Option>
        );
      });
    }
    /**
     * Caso não existam dados de acordo com a busca efetuada
     * será listada a opção abaixo
     */
    return renderNotFound('Nenhum registro encontrado.');
  }, [
    data,
    handleClick,
    inputRef,
    queryClient,
    queryName,
    selected.value,
    setFilter,
    focusedIndex,
    itemsArray,
    renderNotFound,
  ]);

  return (
    <ContentList listWidth={listWidth}>
      <ul ref={wrapperRef} onScroll={onScroll}>
        {data
          ? renderOptions()
          : !isFetching &&
            renderNotFound('Digite ao menos 3 letras para pesquisar.')}
        {isFetching && (
          <Option className="not-found">
            <Spinner animation="border" size="sm" className="spinner" />
          </Option>
        )}
      </ul>
    </ContentList>
  );
};
