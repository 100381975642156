import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import useDebounce from '~/hooks/useDebounce';
import api from '~/services/api';

import { tiposDeBuscaDefault } from './default';
import { Lista } from './Lista';
import {
  BuscaProdutoProps,
  ResponseBuscaProdutoProps,
  SelectType,
} from './protocols';
import { ShowInputSelect } from './ShowInputSelect';
import { InputContainer } from './styles';
import { AlteradosEmPor } from './AlteradosEmPor';
import { moneyFormat } from '~/utils/functions';

/**
  @param {BuscaProdutoProps} BuscaProduto - sla né
  @param clearCampoBusca vai ser responsavel por limpar a ultima pesquisa realizada e voltar o select de buscaPor ao estado inicial.
 */

export const BuscaProduto: React.FC<BuscaProdutoProps> = ({
  label = '',
  placeholder = '',
  name,
  register,
  isError,
  control,
  buscaNasLojas,
  buscaItensInativos = false,
  getProduct,
  customOptions,
  setValue,
  resetField,
  clearCampoBusca,
  shouldApplyFocus = false,
  resetCamposAlterados = false,
  listWidth = undefined,
  ...rest
}: BuscaProdutoProps) => {
  /**
   * debouncedFn
   * Hook de Debounce usado para efetuar a busca após digitação do usuário
   */
  const { debouncedFn } = useDebounce();
  /**
   * inputRef
   * Ref do input utilizado para setar a label da opção selecionada no input
   */
  const inputRef = useRef<HTMLInputElement | null>(null);
  const listaRef = useRef(null);
  const containerRef = useRef<HTMLDivElement>(null);
  /**
   * selected, setSelected
   * Opção selecionada da lista
   */
  const [tiposDeBusca, setTiposDeBusca] = useState<SelectType[]>(() => {
    if (customOptions?.buscarPor) {
      return tiposDeBuscaDefault.filter((tipo) =>
        customOptions.buscarPor?.includes(tipo.label),
      );
    }
    return tiposDeBuscaDefault;
  });
  const [selectedTipoDeBusca, setSelectedTipoDeBusca] = useState<any>({
    label: undefined,
    value: undefined,
  });
  const [selected, setSelected] = useState<any>({
    label: undefined,
    value: undefined,
  });
  /**
   * isOpen
   * Renderiza ou não a lista
   */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  /**
   * filter
   * Filtro passado para a api
   */
  const [filter, setFilter] = useState<string>('');
  const [resetFilter, setResetFilter] = useState(false);
  /**
   * limit
   * Quantidade de registros buscados por vez na api
   */
  const limit = 10;

  /*
    Quando finalizamos uma pesquisa e retornamos para digitar e realizar outra pesquisa,
    a última pesquisa que havia sido feito pelo usuário continuava sendo exibida,
    este useEffect limpa está última pesquisa que havia sido feita
  */
  useEffect(() => {
    if (clearCampoBusca || customOptions?.buscarPor) {
      setSelected({ value: undefined, label: undefined });
      queryClient.removeQueries(`input_select_${name}`);
    }
  }, [clearCampoBusca, customOptions?.buscarPor]);

  useEffect(() => {
    if (inputRef.current && shouldApplyFocus) {
      inputRef.current.focus();
    }
  }, [shouldApplyFocus]);

  /**
   * fetchData
   * Método que se comunica com a api
   */

  /**
   * fetchData
   * Método que se comunica com a api
   */
  function validaFetchData(): { status: boolean; message: string } {
    if (filter === '' || selectedTipoDeBusca.value === undefined) {
      return {
        status: false,
        message: '',
      };
    }
    if (typeof buscaNasLojas === 'object' && buscaNasLojas.length <= 0) {
      return {
        status: false,
        message: 'Selecione uma loja para buscar pelo produto.',
      };
    }
    if (typeof buscaNasLojas === 'number' && buscaNasLojas <= 0) {
      return {
        status: false,
        message: 'Selecione uma loja para buscar pelo produto.',
      };
    }
    return {
      status: true,
      message: 'Selecione uma loja para buscar pelo produto.',
    };
  }
  function formataData(dataFields: any) {
    const fields = dataFields as ResponseBuscaProdutoProps;

    switch (selectedTipoDeBusca.value) {
      case 0:
        return fields.data.map((dt) => {
          const { cod_produto, des_produto, qtd_total_estoque } = dt;
          const formattedQuantity = moneyFormat(
            qtd_total_estoque.toString(),
            3,
            3,
          );

          return {
            value: cod_produto,
            label: `${cod_produto} - ${des_produto} | Estq.: ${formattedQuantity} |`,
          };
        });

      case 1:
        return dataFields.data.map((dt: any) => ({
          value: dt.cod_departamento,
          label: `${dt.cod_departamento} - ${dt.des_departamento}`,
        }));
      case 2:
        return dataFields.data
          .filter((d: any) => d.flg_inativo !== true)
          .map((dt: any) => {
            const formattedNumCpfCnpj = dt?.num_cpf_cnpj?.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              '$1.$2.$3/$4-$5',
            );
            const formattedLocation = dt?.des_cidade
              ? `(${dt?.des_cidade} - ${dt?.des_uf})`
              : '';
            const formattedLabel = `${formattedNumCpfCnpj} - ${dt.nome_pessoa} ${formattedLocation}`;

            return {
              value: dt.cod_fornecedor,
              label: formattedLabel,
            };
          });
      case 3:
        return dataFields.data
          .filter((d: any) => d.flg_inativo !== true)
          .map((dt: any) => ({
            value: dt.num_ncm,
            label: `${dt.num_ncm} - ${dt.des_ncm}`,
          }));
      case 4:
        return dataFields.data.map((dt: any) => ({
          value: dt.cod_lista,
          label: `${dt.cod_lista} - ${dt.des_lista}`,
        }));
      case 5:
        return dataFields.data.map((dt: any) => {
          const formattedQtdEmbalagem =
            dt.des_unidade !== 'KG'
              ? Math.trunc(dt.qtd_embalagem)
              : dt.qtd_embalagem;
          const formattedLabel = `${dt.cod_produto} - ${dt.des_produto} (${dt.des_unidade} c/${formattedQtdEmbalagem})`;
          return {
            value: dt.cod_lista,
            label: formattedLabel,
          };
        });
      case 6:
        return dataFields.data.map((dt: any) => {
          const formattedLabel = `${dt.cod_marca} - ${dt.des_marca}`;
          return {
            value: dt.cod_marca,
            label: formattedLabel,
          };
        });

      default:
        break;
    }
  }
  const fetchData = async ({ pageParam = 1 }): Promise<any> => {
    const isValid = validaFetchData();
    if (filter === '' && !customOptions?.searchBeforeFilter) {
      return {
        count: '0',
        data: [],
        fields: [],
        pagination: {
          lastPage: 0,
          prevPage: 1,
          startPage: 1,
        },
        success: true,
      };
    }
    if (isValid.status === false) {
      if (isValid.message !== '') {
        toast.warning(isValid.message);
      }
      return {
        count: '0',
        data: [],
        fields: [],
        pagination: {
          lastPage: 0,
          prevPage: 1,
          startPage: 1,
        },
        success: true,
      };
    }

    const regexCaracteresEspeciais = /[!@#$^&*()_+{}[\]:;<>,.?~'-]/;
    if (regexCaracteresEspeciais.test(filter)) {
      setFilter('');
      return {
        count: '0',
        data: [],
        fields: [],
        pagination: {
          lastPage: 0,
          prevPage: 1,
          startPage: 1,
        },
        success: true,
      };
    }

    try {
      const { data: dataFields } = await api.get<ResponseBuscaProdutoProps>(
        'busca-produtos',
        {
          params: {
            page: pageParam,
            limit,
            tipoDeBusca: selectedTipoDeBusca.value,
            filter,
            buscaItensInativos,
            lojas: buscaNasLojas,
          },
        },
      );
      const options = formataData(dataFields);
      if (!options) {
        toast.error(
          'Houve um erro ao formatar os dados da busca de produtos. Favor contatar o suporte.',
        );
        return {
          count: '0',
          data: [],
          fields: [],
          pagination: {
            lastPage: 0,
            prevPage: 1,
            startPage: 1,
          },
          success: true,
        };
      }
      if (
        customOptions?.showSelecionarItensContendo &&
        pageParam <= 1 &&
        selectedTipoDeBusca.value === 0
      ) {
        options.unshift({
          label: `Clique aqui para selecionar todos os itens com "${filter}"`,
          value: 0,
          filter,
          selecionarItensContendo: true,
        });
      }
      return {
        ...dataFields,
        data: options,
      };
    } catch (error: any) {
      if (error.data && error.data.message) {
        toast.error(error.data.message);
      } else {
        toast.error(String(error));
      }
      return {
        count: '0',
        data: [],
        fields: [],
        pagination: {
          lastPage: 0,
          prevPage: 1,
          startPage: 1,
        },
        success: true,
      };
    }
  };

  /**
   * useInfiniteQuery
   * Hook do react-query para listagem infinita de dados
   */
  const queryClient = useQueryClient();
  const { data, hasNextPage, fetchNextPage, isFetching, refetch } =
    useInfiniteQuery(
      `input_select_${name}`,
      ({ pageParam = 1 }) => fetchData({ pageParam }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const maxPages = lastPage.pagination.lastPage + 1;
          const nextPage = allPages.length + 1;
          return nextPage <= maxPages ? nextPage : undefined;
        },
        refetchOnWindowFocus: false,
      },
    );

  useEffect(() => {
    if (clearCampoBusca) {
      if (customOptions?.buscaPadrao) {
        const optionFind = tiposDeBuscaDefault.find(
          (tipo) => customOptions?.buscaPadrao === tipo.label,
        );
        setSelectedTipoDeBusca(optionFind);
        if (setValue) setValue('cod_serie', optionFind);
      }
    }
  }, [clearCampoBusca]);

  useEffect(() => {
    if (customOptions?.buscaPadrao) {
      const optionFind = tiposDeBuscaDefault.find(
        (tipo) => customOptions?.buscaPadrao === tipo.label,
      );
      setSelectedTipoDeBusca(optionFind);
      if (setValue) setValue('cod_serie', optionFind);
    }
  }, []);

  useEffect(() => {
    if (customOptions?.buscarPor) {
      setTiposDeBusca(
        tiposDeBuscaDefault.filter((tipo) =>
          customOptions.buscarPor?.includes(tipo.label),
        ),
      );
      if (customOptions?.buscarPor.length === 1) {
        setSelectedTipoDeBusca(
          tiposDeBuscaDefault.find((tipo) =>
            customOptions.buscarPor?.includes(tipo.label),
          ),
        );
      }
    } else {
      setTiposDeBusca(tiposDeBuscaDefault);
    }
  }, [customOptions?.buscarPor]);

  useEffect(() => {
    if (isOpen && filter.length <= 0) {
      setResetFilter(true);
      setSelected({ value: undefined, label: undefined });
    } else {
      setResetFilter(false);
    }
  }, [filter, isOpen]);

  useEffect(() => {
    const handleFocusChange = (event: FocusEvent) => {
      // Timeout para garantir que a verificação ocorra após a mudança de foco
      setTimeout(() => {
        if (
          inputRef.current &&
          !inputRef.current.contains(event.target as Node)
        ) {
          setIsOpen(false);
        } else {
          setIsOpen(true);
        }
      }, 250);
    };

    // Acessa o elemento atual da ref
    const container = containerRef.current;

    if (container) {
      // Adiciona o ouvinte ao container especificado
      container.addEventListener('focusin', handleFocusChange);
    }

    return () => {
      // Garante a remoção do ouvinte para evitar memory leak
      if (container) {
        container.removeEventListener('focusin', handleFocusChange);
      }
    };
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const cursorPosition = event.target.selectionStart;
    const newValue = event.target.value.toUpperCase();
    event.target.value = newValue;
    setFilter(newValue);

    if (newValue.length > 2) {
      setIsOpen(true);
      debouncedFn(() => refetch(), 500);
    }
    if (inputRef.current) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  };

  useEffect(() => {
    const handleFocusChange = () => {
      setTimeout(() => {
        const hasFocusNow =
          containerRef.current !== document.activeElement &&
          listaRef.current !== document.activeElement &&
          inputRef.current !== document.activeElement;
        /**
         * NÃO REMOVER setTimeout
         * Esse método é utilizado para fechar a lista de itens quando o input estiver com foco
         * O problema é que, ao clicar em um item da lista o input perde o foco e o item não é efetivamente clicado
         * O setTimeout gera um delay de 0.1s que permite a efetivação do click.
         */
        if (hasFocusNow) {
          setTimeout(() => {
            setIsOpen(false);
            if (inputRef.current && inputRef.current?.value !== '') {
              inputRef.current.value = '';
              setFilter('');
            }
          }, 100);
        }
      }, 100);
    };

    // Acessa o elemento atual da ref
    const container = containerRef.current;

    if (container) {
      // Adiciona o ouvinte ao container especificado
      container.addEventListener('focusout', handleFocusChange);
    }

    return () => {
      // Garante a remoção do ouvinte para evitar memory leak
      if (container) {
        container.removeEventListener('focusout', handleFocusChange);
      }
    };
  }, []);

  return (
    <InputContainer ref={containerRef}>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ field: { value } }) => {
            const inputValue = (selected?.label || value?.label) ?? '';

            if (inputRef.current?.value === '') {
              const val = inputValue === '' ? placeholder : inputValue;

              inputRef.current.placeholder = val;
            }

            if (inputRef.current?.placeholder && value?.label === undefined) {
              inputRef.current.placeholder = placeholder;
              inputRef.current.value = filter;
            }

            return (
              <>
                <div className="select-container">
                  {/* Exibe o InputSelect Condicionado as customOptions */}
                  <ShowInputSelect
                    customOptions={customOptions}
                    register={register}
                    isError={isError}
                    control={control}
                    tiposDeBusca={tiposDeBusca}
                    setSelectedTipoDeBusca={setSelectedTipoDeBusca}
                    nameBuscaPor={name}
                    setValue={setValue}
                    resetField={resetField}
                    clearCampoBusca={clearCampoBusca} // Propiedade responsavel  por limpar a seleção de buscaPor:
                    {...rest}
                  />
                  {selectedTipoDeBusca.value !== 7 ? (
                    <label>
                      <input
                        {...register(name)}
                        type="text"
                        className={
                          isError ? 'form-control is-invalid' : 'form-control'
                        }
                        placeholder={placeholder || inputValue}
                        onChange={handleChange}
                        autoComplete="off"
                        onClick={(event: any) => {
                          if (selected.label) {
                            event.target.placeholder = selected.label;
                          }
                          setIsOpen(true);
                        }}
                        ref={inputRef}
                        {...rest}
                      />
                      <div className="drop-indicator">
                        <span aria-label="open">
                          {filter && isFetching ? (
                            <Spinner
                              animation="border"
                              size="sm"
                              className="spinner"
                            />
                          ) : (
                            <svg width="24" height="24" viewBox="0 0 24 24">
                              <path
                                d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"
                                fill="currentColor"
                                fillRule="evenodd"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                    </label>
                  ) : (
                    <>
                      <AlteradosEmPor
                        register={register}
                        control={control}
                        setValue={setValue}
                        buscaNasLojas={buscaNasLojas}
                        selectedTipoDeBusca={selectedTipoDeBusca}
                        getProduct={getProduct}
                        resetCampos={resetCamposAlterados}
                        setSelected={setSelected}
                        name={name}
                      />
                    </>
                  )}
                </div>
                {selectedTipoDeBusca.value !== 7 && isOpen && (
                  <Lista
                    wrapperRef={listaRef}
                    inputRef={inputRef}
                    setIsOpen={setIsOpen}
                    setFilter={setFilter}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    data={resetFilter ? undefined : data}
                    selected={selected}
                    setSelected={setSelected}
                    selectedTipoDeBusca={selectedTipoDeBusca}
                    isFetching={isFetching}
                    getProduct={getProduct}
                    queryName={`input_select_${name}`}
                    queryClient={queryClient}
                    buscaNasLojas={buscaNasLojas}
                    buscaItensInativos={buscaItensInativos}
                    setValue={setValue}
                    name={name}
                    listWidth={listWidth}
                  />
                )}
              </>
            );
          }}
        />
      </Form.Group>
    </InputContainer>
  );
};
